import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCart,setSelectedDiscount, selectSelectedDiscount } from '../../Redux/features/CartSlice';
import styles from './Checkout.module.scss';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase setup
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid'; 
import { createRazorpayOrder, openRazorpayPayment, loadRazorpayScript } from '../../services/razorpay_services';
import { saveOrderToFirestore } from '../../services/order_services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import toastify CSS
import { Box, CircularProgress } from '@mui/material'; 
import { useDispatch } from 'react-redux';
import { clearCartInDB } from '../../Redux/features/CartSlice';
import { useNavigate } from 'react-router-dom';
import { createShiprocketOrder } from '../../services/shiprocket_services'; 

export default function Checkout() {
  const { currentUser } = useAuth();
  const { items } = useSelector(selectCart); // Access cart data
  const [userAddress, setUserAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state
  const [paymentProcessing, setPaymentProcessing] = useState(false);// Payment processing state
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const [addressInput, setAddressInput] = useState({
    name: '',
    phone: '',
    street: '',
    street_2: '',
    city: '',
    state: '',
    zipcode: ''
  });
  const selectedDiscount = useSelector(selectSelectedDiscount);
  // Calculate service total based on service time
 

 


  const discountTotal = items.reduce((total, item) => total + ((item.totalPrice) * (item.discount / 100)), 0);
   // Calculate product total based on discounted price and service time
   const productTotal = items.reduce((total, item) => total + (item.discountedPrice * item.quantity), 0);
    
   // Calculate discount for applicable items
   let discountActiveTotal = 0;
   // Track selected discount (optional)
   if (selectedDiscount) {
    // console.log('selectedDiscount',selectedDiscount)
     const applicableItems = items.filter(
       (item) =>
         selectedDiscount.productCategories.includes(item.productType) ||
         selectedDiscount.productIds.includes(item.productId)
     );
 
     const applicableAmount = applicableItems.reduce((total, item) => total + item.discountedPrice * item.quantity, 0);
 
     if (applicableAmount >= selectedDiscount.minOrderAmount) {
       discountActiveTotal = Math.min(
         (applicableAmount * selectedDiscount.discountPercentage) / 100,
         selectedDiscount.maxDiscountAmount
       );
     }
   }
  
  //  useEffect(()=>{
  //   console.log('Active Discount',discountActiveTotal)
  //  },[discountActiveTotal])
  //Discountfinal Total 
  let discountfinalTotal = discountTotal + discountActiveTotal;
   // Final total includes product total minus active discount
   const finalTotal = productTotal - discountfinalTotal;

  // Fetch user's address from Firestore
  useEffect(() => {
    loadRazorpayScript();
    const fetchUserAddress = async () => {
      setLoading(true);
      if (currentUser) {
        const userRef = doc(db, 'Users', currentUser.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setUserAddress(userSnapshot.data().address);
          setUserData(userSnapshot.data());
        }
      }
      setLoading(false);
    };

    fetchUserAddress();
  }, [currentUser]);

  // Handle form changes when editing the address
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle saving the new or edited address
  const handleSaveAddress = async () => {
    if (currentUser) {
      const userRef = doc(db, 'Users', currentUser.uid);
      await updateDoc(userRef, { address: addressInput });
      setUserAddress(addressInput);
      setIsEditing(false);
      toast.success('Address Salved Successfully')
    }
  };

  // Show the address form when the user wants to edit
  const handleEditAddress = () => {
    setIsEditing(true);
    setAddressInput(userAddress || {}); // Prefill the form with existing address if available
  };

// Razorpay payment integration
const handlePayment = async () => {

  if (!userAddress) {
    toast.error("Add Address first")
    return;
  }
  setPaymentProcessing(true); // Start loading when payment starts
  setLoading(true);

  try {
    // Check stock availability for each product in the cart
    const outOfStockItems = [];
    for (const item of items) {
      const productRef = doc(db, 'products', item.productId);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const productData = productSnap.data();
        if (productData.stock < item.quantity) {
          outOfStockItems.push(item.name); // Add to out-of-stock items if stock is less
        }
      }
    }

    if (outOfStockItems.length > 0) {
      // Show toast with out-of-stock items
      toast.error(`The following items are out of stock: ${outOfStockItems.join(', ')}`);
      setPaymentProcessing(false);
      setLoading(false);
      return; // Exit the function if any items are out of stock
    }

    // Step 1: Generate Razorpay Order with the final total (including discount)
    const razorpayOrder = await createRazorpayOrder(finalTotal);  // Use finalTotal here

    // Step 2: Open Razorpay payment interface with the correct final total
    openRazorpayPayment(
      razorpayOrder.id,  // Pass the Razorpay order ID
      finalTotal,        // Pass finalTotal here as well
      currentUser,
      async (paymentResponse) => {
        // Step 3: Save order to Firestore after successful payment
        const orderId = uuidv4();  // Generate a unique order ID
        const paymentDetails = {
          paymentId: paymentResponse.razorpay_payment_id,
          razorpayOrderId: paymentResponse.razorpay_order_id,
          razorpaySignature: paymentResponse.razorpay_signature,
          transactionTime: new Date(),
        };

        // Step 4: Create Shiprocket order after payment is successful
        const orderDetails = {
          order_id: orderId,
          order_date: new Date().toISOString(), // Use a valid date format
          pickup_location: "Primary", // Adjust based on your setup
          channel_id: "5550227", // Channel ID from your Shiprocket setup
          billing_customer_name: userData?.displayName || "John",
          billing_email: userData?.email || "emailnotfound@gmail.com", // Ensure customer name is present
          billing_last_name: userData?.lastName || "", // Ensure last name is present
          billing_address: userAddress?.street || "123 Main St", 
          billing_address_2: userAddress?.street_2 || "Apt 101",
          billing_city: userAddress?.city || "New York",
          billing_pincode: userAddress?.zipcode || "110001",
          billing_state: userAddress?.state || "New York",
          billing_country: "India",
          billing_phone: userAddress?.phone || "1234567890", // Ensure phone is present
          order_items: items.map((item) => ({
            name: item.name,
            sku: item.productId,
            units: item.quantity,
            selling_price: item.price,
          })),
          shipping_is_billing: true,
          payment_method: "Prepaid",
          sub_total: finalTotal,
          length: "10",
          breadth: "5",
          height: "5",
          weight: "1",
        };
        
        const shiprocketResponse = await createShiprocketOrder(orderDetails);
        

        

        // Extract shipment details (e.g., tracking number)
        console.log("shiprocketResponse : ",shiprocketResponse)

        // Step 5: Save the order to Firestore with Shiprocket details
        const { tracking_number = '', shipment_id = '',status='', order_id=''} = shiprocketResponse || {};

        // Step 5: Save the order to Firestore with Shiprocket details
        const updatedOrderData = {
          trackingNumber: tracking_number,  // Save tracking number from Shiprocket
          shipmentId: shipment_id,          // Save shipment ID from Shiprocket
          shipmentBY: 'Shiprocket',         // Specify Shiprocket as the shipping method
          orderStatus: status,     
          shiprocket_order_id: order_id ,      // Update status to shipped
        };

        await saveOrderToFirestore(orderId, currentUser.uid, items, finalTotal, userAddress, {
          ...paymentDetails,
          ...updatedOrderData
        });

        // Step 6: Update product stock in Firestore
        for (const item of items) {
          const productRef = doc(db, 'products', item.productId);
          const productSnap = await getDoc(productRef);

          if (productSnap.exists()) {
            const productData = productSnap.data();
            const newStock = productData.stock - item.quantity;

            // Update the stock in Firestore
            await updateDoc(productRef, {
              stock: newStock,
            });
          }
        }

        // Show success message or redirect to success page
        toast.success('Payment successful and Your order is Confirmed.');
        navigate(`/order/${orderId}`);

        // Dispatch the clearCartInDB thunk to remove items from Firestore and Redux
        dispatch(clearCartInDB(currentUser.uid));
      },
      (errorResponse) => {
        // Handle payment failure
        toast.error('Payment failed. Please try again.', errorResponse);
        setLoading(false);
        setPaymentProcessing(false);
      }
    );
  } catch (error) {
    console.error('Error during payment process:', error);
    toast.error('Error during payment process. Please try again.');
    setPaymentProcessing(false); // End loading when payment completes
    setLoading(false);
  }

  setPaymentProcessing(false); // End loading when payment completes
};

  
  

  // Show loading spinner when fetching user data or processing payment
  if (loading || paymentProcessing) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }


  

  return (
    <div className={styles.checkoutPage}>
      <h1 className={styles.heading}>Checkout</h1>

      <div className={styles.checkoutContent}>
        {/* Address Information */}
        <div className={styles.addressSection}>
          <h2>Delivery Address</h2>

          {userAddress && !isEditing ? (
            <div>
              <p>{userAddress.name}</p>
              <p>{userAddress.street}</p>
              <p>{userAddress.street_2}</p>
              <p>{userAddress.city}, {userAddress.state} - {userAddress.zipcode}</p>
              <p>{userAddress.phone}</p>
              <EditIcon onClick={handleEditAddress} style={{ cursor: 'pointer' }} />
            </div>
          ) : isEditing ? (
            <div className={styles.addressForm}>
              <input
                type="text"
                name="name"
                value={addressInput.name}
                onChange={handleInputChange}
                placeholder="Name"
              />
              <input
                type="text"
                name="phone"
                value={addressInput.phone}
                onChange={handleInputChange}
                placeholder="Phone"
              />
              <input
                type="text"
                name="street"
                value={addressInput.street}
                onChange={handleInputChange}
                placeholder="Street"
              />
              <input
                type="text"
                name="street_2"
                value={addressInput.street_2}
                onChange={handleInputChange}
                placeholder="Street 2"
              />
              <input
                type="text"
                name="city"
                value={addressInput.city}
                onChange={handleInputChange}
                placeholder="City"
              />
              <input
                type="text"
                name="state"
                value={addressInput.state}
                onChange={handleInputChange}
                placeholder="State"
              />
              <input
                type="text"
                name="zipcode"
                value={addressInput.zipcode}
                onChange={handleInputChange}
                placeholder="Zipcode"
              />
              <button onClick={handleSaveAddress}>Save Address</button>
            </div>
          ) : (
            <div>
              <p>No address found</p>
              <button onClick={handleEditAddress}>Add Address</button>
            </div>
          )}
        </div>

        {/* Cart Summary */}
        <div className={styles.summarySection}>
          <h2>Order Summary</h2>
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                {item.name} - ₹{item.discountedPrice} x {item.quantity} = ₹{item.discountedPrice * item.quantity}
              </li>
            ))}
          </ul>
          <p>Discount ₹ {discountfinalTotal}</p>
          <div className={styles.totalAmount}>
            <p>Total Amount:</p>
            <h3>₹{finalTotal}</h3>
          </div>

          {/* Razorpay Payment Button */}
          <button className={styles.payBtn} onClick={handlePayment} disabled={paymentProcessing}>
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
}



// const handlePayment = async () => {
  //   setPaymentProcessing(true); // Start loading when payment starts
  //   try {
  //     // Step 1: Generate Razorpay Order with the final total (including discount)
  //     const razorpayOrder = await createRazorpayOrder(finalTotal);  // Use finalTotal here

  //     // Step 2: Open Razorpay payment interface with the correct final total
  //     openRazorpayPayment(
  //       razorpayOrder.id,  // Pass the Razorpay order ID
  //       finalTotal,        // Pass finalTotal here as well
  //       currentUser,
  //       async (paymentResponse) => {
  //         // Step 3: Save order to Firestore after successful payment
  //         const orderId = uuidv4();  // Generate a unique order ID
  //         const paymentDetails = {
  //           paymentId: paymentResponse.razorpay_payment_id,
  //           razorpayOrderId: paymentResponse.razorpay_order_id,
  //           razorpaySignature: paymentResponse.razorpay_signature,
  //           transactionTime: new Date(),
  //         };
  //         await saveOrderToFirestore(orderId, currentUser.uid, items, finalTotal, userData, paymentDetails);

  //         // Show success message or redirect to success page
  //         toast.success('Payment successful and Your order is Confirmed.');
  //         navigate(`/order/${orderId}`);
  //         // Dispatch the clearCartInDB thunk to remove items from Firestore and Redux
  //          dispatch(clearCartInDB(currentUser.uid));
           
  //       },
  //       (errorResponse) => {
  //         // Handle payment failure
  //         toast.error('Payment failed. Please try again.',errorResponse);
  //       }
  //     );
  //   } catch (error) {
  //     console.error('Error during payment process:', error);
  //     toast.error('Error during payment process. Please try again.');
  //   }
  //   setPaymentProcessing(false); // End loading when payment completes
  // };