import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Make sure to import your Firebase configuration
import styles from "./Products.module.scss";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material"; // Material UI imports

export default function Products() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedType, setSelectedType] = useState("kids");
  const [loading, setLoading] = useState(true); // Loading state to show spinner
  const navigate = useNavigate();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(6000);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((cat) => cat !== category)
        : [...prev, category]
    );
  };

  const handlePriceChange = (type, value) => {
    if (type === "min") {
      setMinPrice(value);
    } else {
      setMaxPrice(value);
    }
  };
  // Fetch products from Firebase on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProducts(productsList); // Save products to state
        setFilteredProducts(productsList);
        // filterProductsByType("kids", productsList); // Set initial filter to 'watch'
        setLoading(false); // Data has been fetched, stop loading
      } catch (error) {
        console.error("Error fetching products: ", error);
        setLoading(false); // Stop loading even on error
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const filtered = products.filter(
      (product) =>
        product.price >= minPrice &&
        product.price <= maxPrice &&
        (selectedCategories.length === 0 ||
          selectedCategories.includes(product.productType))
    );
    setFilteredProducts(filtered);
  }, [minPrice, maxPrice, selectedCategories, products]);

  // Filter products based on productType
  // const filterProductsByType = (type, productList) => {
  //   const filtered = productList.filter(
  //     (product) => product.productType === type
  //   );
  //   setFilteredProducts(filtered);
  //   setSelectedType(type);
  // };

  const handleProductClick = (productId) => {
    // console.log("Navigating to product with ID:", productId);
    navigate(`/product/${productId}`); // Navigate to the product page with the productId
  };

  // Show loader while loading
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <section className={styles.ourProducts}>
      <h1 className={styles.heading}>Products</h1>
      <p className={styles.subHeading}>Trackidoo &gt; Products</p>

      <div className={styles.filtersAndProducts}>
        <div className={styles.filterSection}>
          <div className={styles.filterPrice}>
            <h3>Price Range</h3>
            <div className={styles.priceSlider}>
              <input
                type="range"
                min="0"
                max="10000"
                step="10"
                value={minPrice}
                onChange={(e) =>
                  handlePriceChange("min", parseInt(e.target.value, 10))
                }
              />
              <input
                type="range"
                min="0"
                max="10000"
                step="10"
                value={maxPrice}
                onChange={(e) =>
                  handlePriceChange("max", parseInt(e.target.value, 10))
                }
              />
            </div>
            <div className={styles.priceInput}>
              <label htmlFor="price-min-input">Min: </label>
              <input
                type="number"
                id="price-min-input"
                min="0"
                max="10000"
                value={minPrice}
                onChange={(e) =>
                  handlePriceChange("min", parseInt(e.target.value, 10))
                }
              />
              <label htmlFor="price-max-input">Max: </label>
              <input
                type="number"
                id="price-max-input"
                min="0"
                max="10000"
                value={maxPrice}
                onChange={(e) =>
                  handlePriceChange("max", parseInt(e.target.value, 10))
                }
              />
            </div>
          </div>

          <div class="filter-categories">
            <h3>Categories</h3>
            <ul>
              <li>
                <input
                  type="checkbox"
                  id="kids"
                  checked={selectedCategories.includes("kids")}
                  onChange={() => handleCategoryChange("kids")}
                />
                <label for="category1">Kids Care</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="personaltracker"
                  checked={selectedCategories.includes("personaltracker")}
                  onChange={() => handleCategoryChange("personaltracker")}
                />
                <label for="category2">Personal Tracker</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="vehicle"
                  checked={selectedCategories.includes("vehicle")}
                  onChange={() => handleCategoryChange("vehicle")}
                />
                <label for="category3">Vehicle Care</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="pet"
                  checked={selectedCategories.includes("pet")}
                  onChange={() => handleCategoryChange("pet")}
                />
                <label for="category4">Pet Care</label>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.productGrid}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <div
                key={index}
                className={styles.productCard}
                onClick={() => handleProductClick(product.id)} // Send product.id here
              >
                <div className={styles.productImage}>
                  <img src={`${product.mainImage}`} alt={product.name} />
                </div>
                <h3>{product.name}</h3>
                <p>{product.tagline}</p>
                <p>
                  {" "}
                  {product.discountedPrice == product.price ? (
                    <strong>₹{product.price}</strong>
                  ) : (
                    <strong>
                      ₹{product.discountedPrice}
                      <span
                        style={{
                          textDecoration: "line-through",
                          marginLeft: "10px",
                        }}
                      >
                        ₹{product.price}
                      </span>{" "}
                    </strong>
                  )}
                </p>
              </div>
            ))
          ) : (
            <p>No products available for the selected category.</p>
          )}
        </div>
      </div>
    </section>
  );
}
