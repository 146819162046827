import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust path as needed
import styles from './SalesBanner.module.scss';

const SalesBanner = () => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState({});
  const [banners, setBanners] = useState([]); // Array to store active and hardcoded banners
  const predefinedColors = [
    { bgColor: '#ffd700', textColor: '#333' },
    { bgColor: '#ff5733', textColor: '#fff' },
    { bgColor: '#6a0dad', textColor: '#fff' },
  ];
  // Hardcoded banners with specific colors
  const hardcodedBanners = [
   {
      text: 'Trackidoo - India’s No. 1 GPS is now available on Trackidoo!',
      bgColor: '#ffd700',
      textColor: '#333',
      subText: 'Location manage kare, accurate denga.',
    },
    {
      text: 'Mega Sale - Limited Time Offer!',
      bgColor: '#ff5733',
      textColor: '#fff',
      subText: 'Discounts on top Products, don\'t miss out!',
    },  {
      text: 'Sale is Live - Up to 70% OFF!',
      bgColor: '#6a0dad',
      textColor: '#fff',
      subText: 'HURRY UP, CLOCK IS TICKING DOWN',
    },
  ];

  // Function to calculate time left until the end of the day
  const calculateTimeLeft = () => {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    const difference = endOfDay - now;

    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  // Fetch active discount banners from Firestore
  useEffect(() => {
    const fetchDiscountBanners = async () => {
      const discountsRef = collection(db, 'discount');
      const discountsSnap = await getDocs(discountsRef);
      const activeDiscounts = [];

      let colorIndex = 0; // Index to cycle through predefined colors

      discountsSnap.forEach((doc) => {
        const discountData = doc.data();
        if (discountData.Active && discountData.bannerText) {
          // Assign colors from predefinedColors array in sequence
          const color = predefinedColors[colorIndex % predefinedColors.length];
          activeDiscounts.push({
            text: discountData.bannerText,
            subText: discountData.bannerSubText,
            bgColor: color.bgColor,
            textColor: color.textColor,
          });
          colorIndex += 1;
        }
      });

      // Combine active discounts with hardcoded banners to ensure at least 3 banners
      const combinedBanners = [...activeDiscounts, ...hardcodedBanners].slice(0, 3); // Take only 3
      setBanners(combinedBanners);
    };

    fetchDiscountBanners();
  }, []);


  // Update the time left every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Cycle through banners every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [banners.length]);

  // Current banner to display
  const currentBanner = banners[bannerIndex] || hardcodedBanners[0];

  return (
    <div
      className={styles.salesBanner}
      style={{ backgroundColor: currentBanner.bgColor, color: currentBanner.textColor }}
    >
      <div className={styles.bannerContent}>
        <span className={styles.bannerText}>{currentBanner.text}</span>
        <span className={styles.subText}>{currentBanner.subText}</span>
        <div className={styles.countdown}>
          <span><b>{String(timeLeft.hours).padStart(2, '0')}</b>:</span>
          <span>{String(timeLeft.minutes).padStart(2, '0')}:</span>
          <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
        </div>
      </div>
    </div>
  );
};

export default SalesBanner;
