import React, { useEffect, useState } from 'react';
import styles from './ProductSpecifications.module.scss';
import trackidoologo from '../../Assets/TrakidooLogo.png';
import { db } from "../../firebase"; // Import Firestore database
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions for v9
import ProductDetails from '../ProductDetails.jsx/ProductDetails';
import shadow from '../../Assets/shadow.png';
// Import icons from featureIcons folder
import rangeIcon from '../../Assets/featureIcons/telecommunication.png';
import Dimension from '../../Assets/featureIcons/range.png'
import batteryTypeIcon from '../../Assets/featureIcons/RechargableBattery.png';
import sizeIcon from '../../Assets/featureIcons/wide.png';
import positionAccuracy from '../../Assets/featureIcons/Advanced Tracking.png'
import weightIcon from '../../Assets/featureIcons/weighing-machine.png';
import ColorIcon from '../../Assets/featureIcons/color-palette.png'
import Chipset from '../../Assets/featureIcons/chipset.png'
import NetworkBands from '../../Assets/featureIcons/NetworkBands.png'
import Ram from '../../Assets/featureIcons/ram.png'
import ScreenSize from '../../Assets/featureIcons/screenSize.png'
import Mic from '../../Assets/featureIcons/microphone.png'
import WorkingVoltage from '../../Assets/featureIcons/power.png'
import Frequency from '../../Assets/featureIcons/frequency.png'
import IngressProtection from '../../Assets/featureIcons/IngressProtection.png'
import BluetoothEnabled from '../../Assets/featureIcons/bluetooth.png'
import positioning from '../../Assets/featureIcons/position.png'

const ProductSpecifications = ({ productId }) => {
  const defaultSpecifications = [
    // { name: "Range", value: "Not Available" },
    // { name: "Battery Type", value: "Not Available" },
  
    // { name: "Size", value: "Not Available" },
    // { name: "Weight", value: "Not Available" },
  ];

  const iconMap = {
    "Size": sizeIcon,
    "Operating Voltage": batteryTypeIcon,
    "Positioning Accuracy": positionAccuracy,
    "Location Services": positionAccuracy,
    "Positioning Technologies": positionAccuracy,
    "Location accuracy": positionAccuracy,
    "Weight": weightIcon,
    "Range": rangeIcon,
    "Color": ColorIcon,
    "Battery Life": batteryTypeIcon,
    "Battery Type": batteryTypeIcon,
    "Battery Capacity": batteryTypeIcon,
    "Dimension": Dimension,
    "Main Chipset": Chipset,
    "Network Bands": NetworkBands,
    "Position Tracking" : positionAccuracy,
    "Memory Configuration": Ram,
    "Screen Size": ScreenSize,
    "Speaker and microphone Size": Mic,
    "Working voltage": WorkingVoltage,
    "Input voltage": WorkingVoltage,
    "Frequency": Frequency,
    "Ingress Protection": IngressProtection,
    "Bluetooth Enabled": BluetoothEnabled,
    "Positioning Modes": positioning
  }

  const [productData, setProductData] = useState({});
  const [technicalSpecifications, setTechnicalSpecifications] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const docRef = doc(db, "products", productId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setProductData({
            imageUrl: data.mainImage || trackidoologo,
            name: data.name,
            price: data.price,
            productType: data.productType,
          });
          // Use technical specifications from Firebase or fall back to defaults
          const specifications = data.technicalSpecifications || defaultSpecifications;
          setTechnicalSpecifications(specifications);
          console.log("Fetched technicalSpecifications:", specifications);
        } else {
          console.log("No such document!");
          setTechnicalSpecifications(defaultSpecifications);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        setTechnicalSpecifications(defaultSpecifications);
      }
    };

    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  // Divide technical specifications into two columns
  const halfIndex = Math.ceil(technicalSpecifications.length / 2);
  const leftSpecifications = technicalSpecifications.slice(0, halfIndex);
  const rightSpecifications = technicalSpecifications.slice(halfIndex);

  // Array of four chosen icons to rotate through
  // const icons = [rangeIcon, batteryTypeIcon, sizeIcon, weightIcon];

  const renderSpecification = (spec, index) => {
    const iconSrc = iconMap[spec.name];
    return (
    <div className={styles.specItem} key={spec.name}>
      <img src={iconSrc} alt={`${spec.name} Icon`} />
      {/* <img src={spec.icon || trackidoologo} alt={`${spec.name} Icon`} /> */}
      <p>{spec.name}</p>
      <span>{spec.value}</span>
    </div>
  )};

  return (
    <>
      <div className={styles.specificationsContainer}>
        <h2 className={styles.title}>Technical Specifications</h2>
        <div className={styles.specificationsContent}>
          {/* Left Column for Specifications */}
          <div className={styles.specColumn}>
            {leftSpecifications.map((spec, index) => renderSpecification(spec, index))}
          </div>

          {/* Center Product Image */}
          <div className={styles.specificationsImage}>
            {productData.imageUrl ? (
              <img src={productData.imageUrl} alt="Product" />
            ) : (
              <img src={trackidoologo} alt="Product Placeholder" />
            )}
             <img src={shadow} alt="shadow" />
          </div>

          {/* Right Column for Specifications */}
          <div className={styles.specColumn}>
            {rightSpecifications.map((spec, index) => renderSpecification(spec, halfIndex + index))}
          </div>
        </div>
      </div>
      <ProductDetails productId={productId} />
    </>
  );
};

export default ProductSpecifications;
