import React from "react";
import styles from "./FeaturesMobiles.module.scss";
import gpsTracking from '../../Assets/MobileApp/live-tracking.jpg';
import pathReplay from '../../Assets/MobileApp/path-replay.jpg';
import reportPresentation from '../../Assets/MobileApp/report-presentation.jpg';
import safezoneGeofence from '../../Assets/MobileApp/safezone-geofence.jpg';

const FeaturesMobiles = () => {
    const features = [
        {
          title: "Live GPS Tracking",
          description:
            "Accurately tracks your vehicle's location every 10 seconds, keeping you connected during motion and idle times.",
          image: gpsTracking, // Directly use the imported image
        },
        {
          title: "Path Replay",
          description:
            "Review past routes, stoppages, speeds, and runtimes to stay updated and informed about driving activities.",
          image: pathReplay, // Directly use the imported image
        },
        {
          title: "Report Presentation",
          description:
            "Analyze performance trends and statistics through detailed graphical reports with customizable date ranges.",
          image: reportPresentation, // Directly use the imported image
        },
        {
          title: "Safezone Geofence",
          description:
            "Set virtual zones and get alerts when your vehicle enters or exits for added security and control.",
          image: safezoneGeofence, // Directly use the imported image
        },
      ];
      

  return (
    <section className={styles["features-section"]}>
      <h2 className={styles["features-title"]}>APP Features</h2>
      <p className={styles["features-subtitle"]}>
        Our GPS tracking System lets you manage your assets remotely, giving you
        peace of mind.
      </p>
      <div className={styles["features-container"]}>
        {features.map((feature, index) => (
          <div className={styles["feature-card"]} key={index}>
            <h3 className={styles["feature-title"]}>{feature.title}</h3>
            {/* <p className={styles["feature-description"]}>{feature.description}</p> */}
            <img
              className={styles["feature-image"]}
              src={feature.image}
              alt={feature.title}
            />
            
            
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesMobiles;
