import React, { useState,useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './Slider.module.scss';

// Import your images
import deviceImage from '../../Assets/slider/step1.png';
import simImage from '../../Assets/slider/step2.webp';
import installImage from '../../Assets/slider/steps3.webp';
import activateImage from '../../Assets/slider/steps4.webp';
import { Link } from 'react-router-dom';

const slides = [
  {
    step: "Step 1",
    heading: "You will Need a Hardware",
    description: "A Hardware is needed to transmit the real time location of the vehicle to Onelap cloud server",
    buttonText: "Select your Hardware",
    image: deviceImage,
    tab: "Device",
  },
  {
    step: "Step 2",
    heading: "You will Need a Sim Card",
    description: "The hardware needs internet connectivity to send the data over GSM network",
    buttonText: "Select your plan",
    image: simImage,
    tab: "SIM",
  },
  {
    step: "Step 3",
    heading: "Install the hardware",
    description: "Get the hardware installed with a help of any local technician near you",
    buttonText: "Installation Guide",
    image: installImage,
    tab: "Install",
  },
  {
    step: "Step 4",
    heading: "Activate the hardware",
    description: "Download Trackidoo App from playstore and signup and call our customer support team",
    buttonText: "Activate",
    image: activateImage,
    tab: "Activate",
  },
];

const handleButtonNavigation =()=> {

}

const SliderGettingStarted = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null); // Reference to the Swiper instance

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  const handleTabClick = (index) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index); // Move to the selected slide
    }
  };

  return (
    <section className={styles.slider}>
      <h2 className={styles.sliderTitle}>Getting Started in 4 Steps</h2>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        navigation
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Set the swiper instance
        initialSlide={activeIndex}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className={styles.slideContent}>
              <div className={styles.textContent}>
                <p className={styles.step}>{slide.step}</p>
                <h4 className={styles.heading}>{slide.heading}</h4>
                <p className={styles.description}>{slide.description}</p>
                <Link to={"/products"}  className={styles.slideButton}>{slide.buttonText}</Link>
              </div>
              <div className={styles.imageContent}>
                <img src={slide.image} alt={slide.heading} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation tabs */}
      <div className={styles.sliderTabs}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles.tab} ${activeIndex === index ? styles.active : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {slide.tab}
          </div>
        ))}
      </div>
    </section>
  );
};

export default SliderGettingStarted;