import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../firebase'; // adjust the path as per your project
import { getShiprocketOrderStatus } from '../../services/shiprocket_services'; // Assuming you have the service setup
import './TrackMyOrder.scss';

const TrackOrder = () => {
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const orderRef = doc(db, 'orders', orderId);
      const orderSnap = await getDoc(orderRef);
      if (orderSnap.exists()) {
        const orderData = orderSnap.data();
        setOrder(orderData);

        // Fetch status from ShipRocket
        if (orderData.shiprocket_order_id) {
          const shiprocketStatus = await getShiprocketOrderStatus(orderData.shiprocket_order_id);
          console.log('Shiprocket Order:', shiprocketStatus.data);
          // Update order status in Firestore if different
          if (shiprocketStatus && shiprocketStatus.data.status !== orderData.orderStatus) {
            await updateDoc(orderRef, { orderStatus: shiprocketStatus.data.status });
            setOrder((prev) => ({ ...prev, orderStatus: shiprocketStatus.data.status }));
            console.log('Order Status Updated');
          }
        }
      } else {
        toast.error('Order not found.');
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
      toast.error('Failed to fetch order details.');
    } 
    setLoading(false);
  };

  const handleTrackOrder = () => {
    if (orderId) {
      fetchOrderDetails();
    } else {
      toast.error('Please enter a valid Order Number.');
    }
  };

  const formatTransactionTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString();
    }
    return 'Not Available';
  };

  return (
    <div className="track-order-container">
      <h2>Track Your Order</h2>
      <div className="track-order-box">
        <div className="input-group">
          <label>Order Number</label>
          <input
            type="text"
            placeholder="Enter your order number"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          {/* <label>Email</label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
        </div>
        <div className="separator">
          <span>OR</span>
        </div>
        <div className="input-group">
          <label>Tracking Number</label>
          <input
            type="text"
            placeholder="Enter tracking number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
        </div>
      </div>
      <button className="track-button" onClick={handleTrackOrder} disabled={loading}>
        {loading ? 'Tracking...' : 'Track'}
      </button>
      {order && (
        <div className="order-status">
          <h3>Order Status</h3>
          <p>Status: {order.orderStatus || 'Not Available'}</p>
          <p>Total Price: {order.totalPrice}</p>
          <p>Shipping Method: {order.shippingMethod}</p>
          <p>Transaction Time: {formatTransactionTime(order.transactionTime)}</p>
        </div>
      )}
      <footer>Powered by Shiprocket</footer>
    </div>
  );
};

export default TrackOrder;