import React from "react";
import styles from "./Footer.module.scss";
import TrakidooLogo from "../../Assets/TrakidooLogo.png";

import facebook from '../../Assets/SocialMedia/facebook.png'
import instagram from '../../Assets/SocialMedia/instagram.png'
import Linkdin from '../../Assets/SocialMedia/linkedin.png'
import Twitter from '../../Assets/SocialMedia/twitter.png'
import Youtube from '../../Assets/SocialMedia/youtube.png'

export default function Footer() {
  return (
    <>
      <footer className={styles.footerSection}>
        <div className={styles.footerLinks}>
          <div className={styles.footerLogos}>
            <a href="/">
              <img
                className={styles.anantLogoFooter}
                src={TrakidooLogo}
                alt=""
              />
            </a>
            <br />
            <br />
            <div className={styles.footerText}>
              <b>+91 9999-666-961</b> <br />
              <br />
              <div className={styles.footerTextBox}>
                <div>
                  For order related queries: Reach out to {" "}
                  <a href="mailto:order@trackidoo.com">
                    <b>order@trackidoo.com</b>
                  </a>
                </div>
                <div>
                  For any other queries {" "}
                  <a href="mailto:hello@trackidoo.com">
                    <b>hello@trackidoo.com </b>
                  </a>
                </div>
              </div>
              <br />
              <div className={styles.footerTextBox}>
                <div>
                  <b>Corporate office:</b>
                  <br />
                  A-66, 2nd Floor, Sector-63, Noida, Uttar Pradesh - 201301
                </div>
                {/* <div>
                  <b>Registered Office:</b>
                  <br />
                  S-5, 2nd Floor, Pankaj Mayur plaza-1, Mayur Vihar Phase - 3, New Delhi - 110096
                </div> */}
              </div>
            </div>

             {/* <div class="socials">
              <a
                href="https://www.facebook.com/profile.php?id=100092759263819"
                target="_blank"
              >
                <img
                src={facebook}
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/anantrajcloud/"
                target="_blank"
              >
                <img
                  src="../../../assets/images/SocialMedia/instagram.png"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/anantraj-cloud/?originalSubdomain=in"
                target="_blank"
              >
                <img
                  src="../../../assets/images/SocialMedia/linkedin2.png"
                  alt="linkedin"
                />
              </a>
            </div>  */}
            {/* Social Media Links */}
<div className={styles.socialMedia}>
  <p>Follow Us on Social Media</p>
  <div className={styles.socialIcons}>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <img src={facebook} alt="Facebook" />
    </a>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <img src={instagram} alt="Instagram" />
    </a>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <img src={Linkdin} alt="LinkedIn" />
    </a>
    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
      <img src={Twitter} alt="Twitter" />
    </a>
    <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
      <img src={Youtube} alt="YouTube" />
    </a>
  </div>
</div>
          </div>

          <div className={styles.footerLinksContainer}>
            <div className={styles.links}>
              <p>
                <a>
                  <b>Shop</b>
                </a>
              </p>
              <br />
              <ul>
                <li>
                  <a href="products">Store</a>
                </li>
              </ul>
            </div>

            <div className={styles.links}>
              <p>
                <a href="products">
                  <b>Products</b>
                </a>
              </p>
              <br />
              <ul>
                <li>
                  <a href="kids">Kids Care</a>
                </li>
                <li>
                  <a href="vehicle">Vehicle Care</a>
                </li>
                <li>
                  <a href="pet">Pet Care</a>
                </li>
                <li>
                  <a href="personaltracker">Personal Care</a>
                </li>
              </ul>
            </div>

            <div className={styles.links}>
              <p>
                <a href="blogs">
                  <b>Blogs</b>
                </a>
              </p>
              <br />
              <ul>
                <li>
                  <a href="blogs">Blogs</a>
                </li>
              </ul>
            </div>

            <div className={styles.links}>
              <p>
                <a href="">
                  <b>Orders & Cart</b>
                </a>
              </p>
              <br />
              <ul>
                <li>
                  <a href="order">Your Orders</a>
                </li>
                <li>
                  <a href="cart">Cart</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div className={styles.copyright}>
        <ul>
          <li>
            <a href="">Privacy Policy</a>
          </li>
          <li>
            <a href="">Terms & Conditions</a>
          </li>
          <li>
            <a href="">Cookie Policy</a>
          </li>
          <li>
            <a href="">Recruitement Policy</a>
          </li>
        </ul>


      </div>
    </>
  );
}
