import React from "react";
import styles from "./HeroSection.module.scss"; // Using CSS Modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
import banner1 from "../../Assets/slider/banner-1.webp";
import banner2 from "../../Assets/slider/banner-2.webp";
import banner3 from "../../Assets/slider/banner-3.webp";
import banner4 from "../../Assets/slider/banner-4.webp";
import "swiper/css";
import { Link } from "react-router-dom";
import SalesBanner from "../SalesBanner/SalesBanner";

const HeroSection = () => {
  return (
    <>
    <div className={styles.salesBannerWrapper}>
      <SalesBanner />
      </div>
      <Swiper
        className="mainBannerSwiper"
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Banner Image Background */}
            <Link to={"/product/3okg6j52AU2zlOopZIcp"}>
              <div
                className={styles.imageOut}
                style={{
                  backgroundImage: `url(${require(`../../Assets/slider/banner-1.webp`)})`,
                }}
              >
                <img
                  src={banner1}
                  alt="Paw Safe Banner"
                  className={styles.imageBackground}
                />
              </div>
            </Link>
            {/* Hero Content */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Banner Image Background */}
            <Link to={"/product/FUFEcu3G8cx8mvmSuHM4"}>
              <div className={styles.imageOut}>
                <img
                  src={banner2}
                  alt="Kiddo Smart Watch Banner"
                  className={styles.imageBackground}
                />
              </div>
            </Link>
            {/* Hero Content */}
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Banner Image Background */}
            <Link to={"/product/kMHbAbOjTDHrI8IuTAAI"}>
              <div className={styles.imageOut}>
                <img
                  src={banner3}
                  alt="Drive Safe Banner"
                  className={styles.imageBackground}
                />
              </div>
            </Link>
            {/* Hero Content */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Banner Image Background */}
            <Link to={"/product/J9hDlTCCtAq8tFVTTpoA"}>
              <div className={styles.imageOut}>
                <img
                  src={banner4}
                  alt="Tag It Gps Tag Banner"
                  className={styles.imageBackground}
                />
              </div>
            </Link>
            {/* Hero Content */}
            {/* <div className={styles.heroContent}>
    <h1>
      Track, Trace, <br /> and Protect
    </h1>
    <a href="vehicle" className={styles.exploreButton}>
      Explore Products
    </a>
  </div> */}
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HeroSection;
