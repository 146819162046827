import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Product.module.scss";
import { CircularProgress, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  fetchCart,
  selectCart,
} from "../../Redux/features/CartSlice";
import ProductSpecificationsWithIcons from "../../components/ProductDetails.jsx/ProductDetails";
import ProductSpecifications from "../../components/ProductSpecification/ProductSpecification";
import shadow from "../../Assets/shadow.png";

export default function Product() {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serviceTime, setServiceTime] = useState(1); // State for Service Time dropdown
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items } = useSelector(selectCart);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", productId);
        const productSnapshot = await getDoc(productRef);

        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          // console.log('Product Data :',productData);
          setProductData({
            ...productData,
            TempMainImage: productData.mainImage,
          });
        } else {
          console.error("No such product!");
          toast.error("Error: Product not found.");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();

    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        dispatch(fetchCart(currentUser.uid));
      } else {
        setUser(null);
      }
    });
  }, [productId, dispatch]);
  // useEffect(()=> {
  //   console.log('Product Data :',productData?.Service);
  // },[productData])

  const handleAddToCart = async () => {
    if (!user) {
      navigate("/login");
    } else {
      const existingItem = items.find((item) => item.productId === productId);
      if (existingItem) {
        toast.error("Item is already added in your cart");
        navigate("/Cart");
        return;
      }
      const cartItem = {
        productId: productId,
        name: productData.name,
        quantity: 1,
        price:  productData.price,
        priceThreeYear:productData?.priceThreeYear || null,
        discount: productData.discount,
        discountedPrice:
          (serviceTime === 3 ? productData.priceThreeYear : productData.price) *
          (1 - productData.discount / 100),
        totalPrice: serviceTime === 3 ? productData.priceThreeYear : productData.price,
        mainImage: productData.mainImage,
        serviceTime: serviceTime,
        Service: productData?.Service,
        productType: productData?.productType,
      };

      console.log("Cart Item:", cartItem);

      try {
        const cartRef = doc(db, "carts", user.uid);
        const cartSnapshot = await getDoc(cartRef);

        if (cartSnapshot.exists()) {
          await updateDoc(cartRef, {
            items: arrayUnion(cartItem),
            updatedAt: new Date(),
          });
        } else {
          await setDoc(cartRef, {
            items: [cartItem],
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        }

        dispatch(addItemToCart(cartItem));
        toast.success("Product added to cart successfully!");
        navigate("/Cart");
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Error adding product to cart.");
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!productData) {
    return <p>Loading product...</p>;
  }

  return (
    <>
      <div className={styles.productPage}>
        <div className={styles.imageSection}>
          <div className={styles.mainImage}>
            <img src={productData.TempMainImage} alt="Main Product" />
            <img className={styles.shadow} src={shadow} alt="shadow" />
          </div>
          <div className={styles.imageThumbnails}>
            {productData?.galleryImages?.map((image, index) => (
              <div
                key={index}
                className={styles.thumbnail}
                onMouseEnter={() =>
                  setProductData({ ...productData, TempMainImage: image })
                }
                onMouseLeave={() =>
                  setProductData({
                    ...productData,
                    TempMainImage: productData.mainImage,
                  })
                }
              >
                <img
                  src={image || productData.mainImage}
                  alt={`Thumbnail ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.productDetails}>
          <div className={styles.productHeader}>
            <h1>
              {productData.name}
              {productData?.simIncluded ? (
                <span className="text-base ml-2">(Sim Included)</span>
              ) : (
                <span className="text-base ml-2">(Sim Not Included)</span>
              )}
            </h1>
          </div>

          <p className={styles.description}>{productData.tagline}</p>

          <p className={styles.price}>
            {productData.discountedPrice === productData.price ? (
              <strong>₹{serviceTime === 3 ? productData.priceThreeYear : productData.price}</strong>
            ) : (
              <>
                <strong>₹{serviceTime === 3 ? productData.priceThreeYear * (1 - productData.discount / 100) : productData.price * (1 - productData.discount / 100)}</strong>
                <span style={{ textDecoration: "line-through", marginLeft: "10px" }}>
                  ₹{serviceTime === 3 ? productData.priceThreeYear : productData.price}
                </span>
              </>
            )}
          </p>

          <div className={styles.cartSection}>
            <button className={styles.addToCartBtn} onClick={handleAddToCart}>
              Add to Cart
            </button>
            {productData?.Service ? (
              <select
                className={styles.serviceTimeDropdown}
                value={serviceTime}
                onChange={(e) => setServiceTime(Number(e.target.value))}
              >
                <option value={1}>Service time: 1 Year</option>
                <option value={3}>Service time: 3 Years</option>
              </select>
            ) : (
              <p className="text-xl text-center">Service time: 1</p>
            )}
          </div>
          <p className={styles.description}>{productData.description}</p>
          <br />
          {/* <div className={styles.productFeatures}>
            {productData?.featuresIcons?.includes(
              "90 days tracking history"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/90daysTrackingHistory.png")}
                    alt="90 days tracking history"
                  />
                </div>
                <span>90 days tracking history</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Advanced tracking") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Advanced Tracking.png")}
                    alt="Advanced tracking"
                  />
                </div>
                <span>Advanced tracking</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Rechargable battery") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/RechargableBattery.png")}
                    alt="Rechargable battery"
                  />
                </div>
                <span>Rechargable battery</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Two-Way Call") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/twoWayCall.png")}
                    alt="Two-Way Call"
                  />
                </div>
                <span>Two-Way Call</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("SOS") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/SOS.png")}
                    alt="SOS"
                  />
                </div>
                <span>SOS</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Geo Fence Alert") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/GeoFence.png")}
                    alt="Geo Fence"
                  />
                </div>
                <span>Geo Fence</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Precise Location") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/preciseLocation.png")}
                    alt="Precise Location"
                  />
                </div>
                <span>Precise Location</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Waterproof") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/waterProof.png")}
                    alt="Waterproof"
                  />
                </div>
                <span>Waterproof</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("HD Camera") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/HDCamera.png")}
                    alt="HD Camera"
                  />
                </div>
                <span>HD Camera</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("SOSPet") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/SOSCat.png")}
                    alt="SOS"
                  />
                </div>
                <span>SOS</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Instant Alert Pet") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/InstantAlertPet.png")}
                    alt="Instant Alert"
                  />
                </div>
                <span>Instant Alert</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("WaterproofPet") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/WaterProofPet.png")}
                    alt="Waterproof"
                  />
                </div>
                <span>Waterproof</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Custom geo-fence Pet") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/GeoFencingpet.png")}
                    alt="Custom geo-fence"
                  />
                </div>
                <span>Custom geo-fence</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Real Time Tracking") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/realtimeTracking.png")}
                    alt="Real Time Tracking"
                  />
                </div>
                <span>Real Time Tracking</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Wireless") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/wireless.png")}
                    alt="Wireless"
                  />
                </div>
                <span>Wireless</span>
              </div>
            ) : (
              ""
            )}

            {productData?.featuresIcons?.includes("Playback History") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/PlaybackHistory.png")}
                    alt="Playback History"
                  />
                </div>
                <span>Playback History</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Quick Installation") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/QuickInstallation.png")}
                    alt="Quick Installation"
                  />
                </div>
                <span>Quick Installation</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "Compatible with 16-pin OBDII port"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/CompatibleWithOBDII.png")}
                    alt="Compatible with 16-pin OBDII port"
                  />
                </div>
                <span>Compatible with 16-pin OBDII port</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "Vibration and movement alarms"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Vibration.png")}
                    alt="Vibration and movement alarms"
                  />
                </div>
                <span>Vibration and movement alarms</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("High GPS Sensitivity") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/GPSSensitive.png")}
                    alt="High GPS Sensitivity"
                  />
                </div>
                <span>High GPS Sensitivity</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Two USB Ports") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/TwoUSBPorts.png")}
                    alt="Two USB Ports"
                  />
                </div>
                <span>Two USB Ports</span>
              </div>
            ) : (
              ""
            )}

            {productData?.featuresIcons?.includes("Smart Storage") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/smartStorage.png")}
                    alt="Smart Storage"
                  />
                </div>
                <span>Smart Storage</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("1080P HD recording.") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/1080HDCamera.png")}
                    alt="1080P HD recording."
                  />
                </div>
                <span>1080P HD recording.</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Anti-Lost Alarm") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/collisionAlarm.png")}
                    alt="Vibration and Collision Alarms"
                  />
                </div>
                <span>Vibration and Collision Alarms</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Low Power Consumption") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/LowPowerConsumption.png")}
                    alt="Low Power Consumption"
                  />
                </div>
                <span>Low Power Consumption</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Anti-Lost Alarm") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/antiLostAlarm.png")}
                    alt="Anti-Lost Alarm"
                  />
                </div>
                <span>Anti-Lost Alarm</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Remote Control") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/remoteControl.png")}
                    alt="Remote Control"
                  />
                </div>
                <span>Remote Control</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Light Weight") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/LightWeight.png")}
                    alt="Light Weight"
                  />
                </div>
                <span>Light Weight</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Support 2g Network") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/wireless.png")}
                    alt="Support 2g Network"
                  />
                </div>
                <span>Support 2g Network</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Support 4g Network") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Advanced Tracking.png")}
                    alt="Support 4g Network"
                  />
                </div>
                <span>Support 4g Network</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("OverSpeed Alarm") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Vibration.png")}
                    alt="OverSpeed Alarm"
                  />
                </div>
                <span>OverSpeed Alarm</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Dual USB Charging Ports") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/TwoUSBPorts.png")}
                    alt="Dual USB Charging Ports"
                  />
                </div>
                <span>Dual USB Charging Ports</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Bluetooth Enabled") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/wireless.png")}
                    alt="Bluetooth Enabled"
                  />
                </div>
                <span>Bluetooth Enabled</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Driver Behaviour") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Advanced Tracking.png")}
                    alt="Driver Behaviour"
                  />
                </div>
                <span>Driver Behaviour</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Multiple Alarm") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/Vibration.png")}
                    alt="Multiple Alarm"
                  />
                </div>
                <span>Multiple Alarm</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Remote Shutdown") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/remoteControl.png")}
                    alt="Remote Shutdown"
                  />
                </div>
                <span>Remote Shutdown</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("SOS/ family number") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/SOS.png")}
                    alt="SOS/ family number"
                  />
                </div>
                <span>SOS/ family number</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("800 Mah Battery") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/RechargableBattery.png")}
                    alt="800 Mah Battery"
                  />
                </div>
                <span>800 Mah Battery</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "battery backup of 2-3 days"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/LowPowerConsumption.png")}
                    alt="battery backup of 2-3 days"
                  />
                </div>
                <span>battery backup of 2-3 days</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("One way Calling") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/twoWayCall.png")}
                    alt="One way Calling"
                  />
                </div>
                <span>One way Calling</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("Fast Connection Setup") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/QuickInstallation.png")}
                    alt="Fast Connection Setup"
                  />
                </div>
                <span>Fast Connection Setup</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "battery backup of 5-7 days"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/LowPowerConsumption.png")}
                    alt="battery backup of 5-7 days"
                  />
                </div>
                <span>battery backup of 5-7 days</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "Built in Mic Audio Acquisition"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/HDCamera.png")}
                    alt="Built in Mic Audio Acquisition"
                  />
                </div>
                <span>Built in Mic Audio Acquisition</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes("TF card video storage") ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/smartStorage.png")}
                    alt="TF card video storage"
                  />
                </div>
                <span>TF card video storage</span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "4G CAT1 full network high-definition live streaming and playback"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/1080HDCamera.png")}
                    alt="4G CAT1 full network high-definition live streaming and playback"
                  />
                </div>
                <span>
                  4G CAT1 full network high-definition live streaming and
                  playback
                </span>
              </div>
            ) : (
              ""
            )}
            {productData?.featuresIcons?.includes(
              "GPS+BD dual mode real-time positioning"
            ) ? (
              <div className={styles.productFeatureSingle}>
                <div className={styles.featureIcon}>
                  <img
                    src={require("../../Assets/featureIcons/realtimeTracking.png")}
                    alt="GPS+BD dual mode real-time positioning"
                  />
                </div>
                <span>GPS+BD dual mode real-time positioning</span>
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
      </div>

      <div>
        <ProductSpecifications productId={productId} />
      </div>
{/* 
      <div className={styles.specifications}>
        <h1>Specifications</h1>

        <div className={styles.specificationsContainer}>
          <ul>
            {productData.features &&
              productData.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
          </ul>

          <div
            className={styles.specificationImage}
            style={{ backgroundImage: `url(${productData.featuresImage})` }}
          ></div>
        </div>
      </div> */}
    </>
  );
}

