import React, { useEffect, useState } from 'react';
import styles from './ProductDetails.module.scss';
import trackidoologo from '../../Assets/TrakidooLogo.png';
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

// Import icons directly
import Icon90DaysTrackingHistory from '../../Assets/featureIcons/90daysTrackingHistory.png';
import IconAdvancedTracking from '../../Assets/featureIcons/Advanced Tracking.png';
import IconRechargableBattery from '../../Assets/featureIcons/RechargableBattery.png';
import IconTwoWayCall from '../../Assets/featureIcons/twoWayCall.png';
import IconSOS from '../../Assets/featureIcons/SOS.png';
import IconGeoFence from '../../Assets/featureIcons/GeoFence.png';
import IconPreciseLocation from '../../Assets/featureIcons/preciseLocation.png';
import IconWaterproof from '../../Assets/featureIcons/waterProof.png';
import IconHDCamera from '../../Assets/featureIcons/HDCamera.png';
import IconSOSPet from '../../Assets/featureIcons/SOSCat.png';
import IconInstantAlertPet from '../../Assets/featureIcons/InstantAlertPet.png';
import IconWaterproofPet from '../../Assets/featureIcons/WaterProofPet.png';
import IconCustomGeoFencePet from '../../Assets/featureIcons/GeoFencingpet.png';
import IconWireless from '../../Assets/featureIcons/wireless.png';
import IconRealTimeTracking from '../../Assets/featureIcons/realtimeTracking.png';
import IconPlaybackHistory from '../../Assets/featureIcons/PlaybackHistory.png';
import IconQuickInstallation from '../../Assets/featureIcons/QuickInstallation.png';
import IconCompatibleOBDII from '../../Assets/featureIcons/CompatibleWithOBDII.png';
import IconVibrationAlarm from '../../Assets/featureIcons/Vibration.png';
import IconGPSSensitivity from '../../Assets/featureIcons/GPSSensitive.png';
import IconTwoUSBPorts from '../../Assets/featureIcons/TwoUSBPorts.png';
import IconSmartStorage from '../../Assets/featureIcons/smartStorage.png';
import Icon1080HD from '../../Assets/featureIcons/1080HDCamera.png';
import IconCollisionAlarms from '../../Assets/featureIcons/collisionAlarm.png';
import IconLowPowerConsumption from '../../Assets/featureIcons/LowPowerConsumption.png';
import IconAntiLostAlarm from '../../Assets/featureIcons/antiLostAlarm.png';
import IconRemoteControl from '../../Assets/featureIcons/remoteControl.png';
import IconLightWeight from '../../Assets/featureIcons/LightWeight.png';
import shadow from '../../Assets/shadow.png';
import Bluetooth from '../../Assets/featureIcons/bluetooth.png'

const iconMap = {
  "Two-Way Call": IconTwoWayCall,
  "SOS": IconSOS,
  "Geo Fence Alert": IconGeoFence,
  "Support 2g Network": IconWireless, // Closest to wireless connectivity
  "Support 4g Network": IconAdvancedTracking, // Closest match for network support
  "OverSpeed Alarm": IconVibrationAlarm, // Closest match to alarms/alerts
  "Precise Location": IconPreciseLocation,
  "Waterproof": IconWaterproof,
  "HD Camera": IconHDCamera,
  "SOSPet": IconSOSPet,
  "Instant Alert Pet": IconInstantAlertPet,
  "WaterproofPet": IconWaterproofPet,
  "Custom geo-fence Pet": IconCustomGeoFencePet,
  "Wireless": IconWireless,
  "Real Time Tracking": IconRealTimeTracking,
  "Playback History": IconPlaybackHistory,
  "Quick Installation": IconQuickInstallation,
  "Compatible with 16-pin OBDII port": IconCompatibleOBDII,
  "Vibration and movement alarms": IconVibrationAlarm,
  "High GPS Sensitivity": IconGPSSensitivity,
  "Dual USB Charging Ports": IconTwoUSBPorts, // Assuming Two USB Ports icon
  "Smart Storage": IconSmartStorage,
  "1080P HD recording": Icon1080HD,
  "Vibration and Collision Alarms": IconCollisionAlarms,
  "Low Power Consumption": IconLowPowerConsumption,
  "Anti-Lost Alarm": IconAntiLostAlarm,
  "Remote Control": IconRemoteControl,
  "Light Weight": IconLightWeight,
  "90 days tracking history": Icon90DaysTrackingHistory,
  "Advanced tracking": IconAdvancedTracking,
  "Rechargable battery": IconRechargableBattery,
  "Bluetooth Enabled": Bluetooth, // Closest match for Bluetooth
  "Driver Behaviour": IconAdvancedTracking, // Closest match to driver or tracking
  "Multiple Alarm": IconVibrationAlarm, // Closest match to multiple alarms
  "Remote Shutdown": IconRemoteControl,
  "SOS/ family number": IconSOS,
  "800 Mah Battery": IconRechargableBattery,
  "battery backup of 2-3 days": IconLowPowerConsumption, // Closest match for battery
  "One way Calling": IconTwoWayCall,
  "Fast Connection Setup": IconQuickInstallation, // Closest match for quick setup
  "battery backup of 5-7 days": IconLowPowerConsumption,
  "Built in Mic Audio Acquisition": IconHDCamera, // Closest match to audio/video
  "TF card video storage": IconSmartStorage, // Closest match for storage
  "4G CAT1 full network high-definition live streaming and playback": Icon1080HD, // Closest match to HD video
  "GPS+BD dual mode real-time positioning": IconRealTimeTracking, // Closest match for tracking
};

const ProductDetails = ({ productId }) => {
  const [productData, setProductData] = useState({
    features: [],
    featuresIcons: []
  });

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const docRef = doc(db, "products", productId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setProductData({
            imageUrl: data.mainImage || trackidoologo,
            name: data.name || "Product Name",
            price: data.price || 0,
            tagline: data.tagline || "Your Product Tagline",
            features: data.features || [],
            featuresIcons: data.featuresIcons || []
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  // Limiting icons to the first 6 and ensuring they exist in iconMap
  const limitedIcons = productData.featuresIcons
    .filter(iconName => iconMap[iconName]) // Filter only icons that exist in iconMap
    .slice(0, 6); // Limit to 6 icons

  const halfIndex = Math.ceil(limitedIcons.length / 2);
  const leftIcons = limitedIcons.slice(0, halfIndex);
  const rightIcons = limitedIcons.slice(halfIndex);

  const renderFeatureIcon = (iconName) => {
    const iconSrc = iconMap[iconName];
    return iconSrc ? (
      <div className={styles.productFeatureSingle} key={iconName}>
        <div className={styles.featureIcon}>
          <img src={iconSrc} alt={iconName} />
        </div>
        <span>{iconName}</span>
      </div>
    ) : null;
  };

  return (
    <div className={styles.productDetailContainer}>
      <h2 className={styles.title}>{productData.name}</h2>
      <p className={styles.tagline}>{productData.tagline}</p>

      <div className={styles.productDetailContent}>
        {/* Left Column for Feature Icons */}
        <div className={styles.featuresColumn}>
          {leftIcons.map((iconName) => renderFeatureIcon(iconName))}
        </div>

        {/* Center Product Image */}
        <div className={styles.productImageContainer}>
          <img src={productData.imageUrl} alt="Product" />
          {/* <img className={styles.shadow} src={shadow} alt="shadow" /> */}
        </div>

        {/* Right Column for Feature Icons */}
        <div className={styles.featuresColumn}>
          {rightIcons.map((iconName) => renderFeatureIcon(iconName))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

// // ProductDetail.js

// import React, { useEffect, useState } from 'react';
// import styles from './ProductDetails.module.scss';
// import trackidoologo from '../../Assets/TrakidooLogo.png';
// import { db } from "../../firebase"; // Import Firestore database
// import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions for v9

// const ProductDetail = ({ productId }) => {
//   const [productData, setProductData] = useState({});

//   useEffect(() => {
//     const fetchProductData = async () => {
//       try {
//         const docRef = doc(db, "products", productId);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           setProductData({
//             imageUrl: data.mainImage,
//             name: data.name,
//             price: data.price,
//             features: data.features || [], // Added to display features
//             featuresIcons: data.featuresIcons || [] // Added if icons are part of data
//           });
//           console.log("Product Data : ",data)
//         } else {
//           console.log("No such document!");
//         }
//       } catch (error) {
//         console.error("Error fetching product data:", error);
//       }
//     };

//     if (productId) {
//       fetchProductData();
//     }
//   }, [productId]);

//   return (
//     <div className={styles.productDetailContainer}>
//       <h2 className={styles.title}>{productData.name}</h2>
//       <div className={styles.productDetailContent}>
//         <div className={styles.featuresColumn}>
//           {productData?.features.map((feature, index) => (
//             <div key={index} className={styles.featureItem}>
//               <img
//                 src={productData.featuresIcons[index] || trackidoologo}
//                 alt={`Feature ${index + 1}`}
//                 className={styles.featureIcon}
//               />
//               <p>{feature}</p>
//             </div>
//           ))}
//         </div>

//         <div className={styles.productImageContainer}>
//           {productData?.imageUrl ? (
//             <img src={productData.imageUrl} alt="Product" />
//           ) : (
//             <img src={trackidoologo} alt="Product Placeholder" />
//           )}
//         </div>

//         <div className={styles.featuresColumn}>
//           {productData?.features.map((feature, index) => (
//             <div key={index} className={styles.featureItem}>
//               <img
//                 src={productData.featuresIcons[index] || trackidoologo}
//                 alt={`Feature ${index + 1}`}
//                 className={styles.featureIcon}
//               />
//               <p>{feature}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;
// ProductDetail.js

// ProductDetail.js

// ProductDetail.js