import React, { useEffect, useState } from 'react';
import { doc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { updateItemQuantity, removeItemFromCart, fetchCart, selectCart,setSelectedDiscount, selectSelectedDiscount  } from '../../Redux/features/CartSlice';
import { onAuthStateChanged } from 'firebase/auth';
import { Box, Typography, Button, CircularProgress, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './Cart.module.scss';
import arrowup from '../../Assets/upload.png';
import arrowdown from '../../Assets/download.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Cart() {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const { items, status } = cart;
  const [user, setUser] = useState(null);
  const [discounts, setDiscounts] = useState([]); // Store all active discounts
  const selectedDiscount = useSelector(selectSelectedDiscount);// Store the selected discount
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        dispatch(fetchCart(currentUser.uid));
        fetchDiscounts(); // Fetch all active discounts when user is logged in
      } else {
        setUser(null);
      }
    });
  }, [dispatch]); 

  const fetchDiscounts = async () => {
    const discountsRef = collection(db, 'discount');
    const discountsSnap = await getDocs(discountsRef);
    const activeDiscounts = [];

    discountsSnap.forEach((doc) => {
      const discountData = doc.data();
      if (discountData.Active) {
        activeDiscounts.push({ id: doc.id, ...discountData });
      }
    });

    setDiscounts(activeDiscounts);
  };

  const updateQuantity = async (productId, quantity) => {
    if (user) {
      const cartRef = doc(db, 'carts', user.uid);
      const updatedItems = items.map(item =>
        item.productId === productId
          ? { ...item, quantity, totalPrice: item.discountedPrice * quantity }
          : item
      );

      const finalItems = updatedItems.filter(item => item.quantity > 0);
      await updateDoc(cartRef, { items: finalItems });
      dispatch(updateItemQuantity({ productId, quantity }));
      toast.success('Item Quantity Updated successfully!');
    }
  };

  const deleteItem = async (productId) => {
    if (user) {
      const cartRef = doc(db, 'carts', user.uid);
      const updatedItems = items.filter(item => item.productId !== productId);
      await updateDoc(cartRef, { items: updatedItems });
      dispatch(removeItemFromCart(productId));
      toast.success('Item Removed successfully!');
    }
  };
  const handleServiceTimeChange = async (productId, newServiceTime) => {
    if (user) {
      const cartRef = doc(db, 'carts', user.uid);
  
      const updatedItems = items.map(item => {
        if (item.productId === productId) {
          // Use priceThreeYear only if serviceTime is 3, otherwise use the regular price
          const selectedPrice = newServiceTime === 3 ? item.priceThreeYear : item.price;
          const selectedDiscountedPrice = selectedPrice * (1 - (item.discount || 0) / 100);
          
          return {
            ...item,
            serviceTime: newServiceTime,
            discountedPrice: selectedDiscountedPrice, // Recalculate discounted price based on the selected service time
            totalPrice: selectedDiscountedPrice * item.quantity, // Update totalPrice based on the recalculated discounted price
          };
        }
        return item;
      });
  
      try {
        await updateDoc(cartRef, { items: updatedItems });
        dispatch(fetchCart(user.uid)); // Refresh the cart in Redux
        toast.success('Service Time Updated successfully!');
      } catch (error) {
        console.error("Error updating service time:", error);
        toast.error("Failed to update service time. Please try again.");
      }
    }
  };
  
  
  

  const productTotal = items.reduce((total, item) => total + item.discountedPrice * item.quantity, 0);
  const discountTotal = items.reduce((total, item) => total + item.totalPrice * (item.discount / 100), 0);

  let discountActiveTotal = 0;
  if (selectedDiscount) {
    
    const applicableItems = items.filter(
      (item) =>
        selectedDiscount.productCategories.includes(item.productType) ||
        selectedDiscount.productIds.includes(item.productId)
    );

    const applicableAmount = applicableItems.reduce((total, item) => total + item.discountedPrice * item.quantity, 0);

    if (applicableAmount >= selectedDiscount.minOrderAmount) {
      discountActiveTotal = Math.min(
        (applicableAmount * selectedDiscount.discountPercentage) / 100,
        selectedDiscount.maxDiscountAmount
      );
    }
  }

  const finalTotal = productTotal - discountTotal - discountActiveTotal;

  const handleProceedToCheckout = () => {
    navigate('/checkout');
  };

  if (status === 'loading') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (items.length === 0) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" gutterBottom>
          Your Cart is Empty
        </Typography>
        <Button variant="contained" color="primary" href="/products">
          Shop Now
        </Button>
      </Box>
    );
  }

  return (
    <div className={styles.cartPage}>
      <h1 className={styles.heading}>Cart</h1>

      <FormControl fullWidth style={{ marginBottom: '1rem' }}>
        <InputLabel>Select Offer</InputLabel>
        <Select
          value={selectedDiscount ? selectedDiscount.id : ''}
          onChange={(e) => {
            const selected = discounts.find((discount) => discount.id === e.target.value);
            dispatch(setSelectedDiscount(selected)); // Use Redux dispatch to set the selected discount
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {discounts.map((discount) => (
            <MenuItem key={discount.id} value={discount.id}>
              {discount.discountName} - {discount.discountPercentage}% Off
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={styles.cartContent}>
        <table className={styles.cartTable}>
          <thead>
            <tr>
              <th>Products</th>
              <th>Quantity</th>
              <th>Service Time</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr className={styles.cartItem} key={index}>
                <td>
                  <div className={styles.productImage_Details}>
                    <Link to={`/product/${item.productId}`}>
                      <img src={item.mainImage} alt={item.name} className={styles.productImage} />
                    </Link>
                    <div className={styles.productDetails}>
                      <h3>{item.name}</h3>
                      <p className={styles.price}>
                        {item.price == item.discountedPrice || item.discountedPrice == item.priceThreeYear ? (
                          <strong>₹{item.discountedPrice}</strong>
                        ) : (
                          <>
                            <strong>₹{item.discountedPrice}</strong>
                            <span style={{ textDecoration: 'line-through', marginLeft: '10px' }}>
                              ₹{item.price}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className={styles.quantityInput}>
                    <p>{item.quantity}</p>
                    <div className={styles.increaseDecreaseButtons}>
                      <button onClick={() => updateQuantity(item.productId, item.quantity + 1)}>
                        <img src={arrowup} alt="Increase Quantity" />
                      </button>
                      <button
                        onClick={() => updateQuantity(item.productId, item.quantity - 1)}
                        disabled={item.quantity === 1}
                      >
                        <img src={arrowdown} alt="Decrease Quantity" />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  {item?.Service ? (
                    <select
                      className={styles.serviceTimeDropdown}
                      value={item.serviceTime || 1}
                      onChange={(e) => handleServiceTimeChange(item.productId, Number(e.target.value))}
                    >
                      <option value={1}>Service Time: 1</option>
                      <option value={3}>Service Time: 3</option>
                    </select>
                  ) : (
                    <p>Service Time: {item.serviceTime}</p>
                  )}
                </td>
                <td>₹{item.discountedPrice * item.quantity}</td>
                <td>
                  <IconButton onClick={() => deleteItem(item.productId)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.priceSummary}>
          <h2>Summary</h2>
          <div className={styles.summaryDetail}>
            <p>Product Total</p>
            <p>₹{productTotal}</p>
          </div>

          <div className={styles.summaryDetail}>
            <p>Discount</p>
            <p>₹{discountActiveTotal}</p>
          </div>
          <div className={styles.summaryTotal}>
            <h3>Total</h3>
            <h3>₹{finalTotal}</h3>
          </div>
          <button className={styles.checkoutBtn} onClick={handleProceedToCheckout}>
            Proceed to Checkout
          </button>
        </div>
      </div>
    </div>
  );
}
